
import React, { useState, useEffect } from 'react';

import image1 from '../assets/1.png';
import image2 from '../assets/2.png';
import image3 from '../assets/3.png';
import image4 from '../assets/4.png';
import image5 from '../assets/5.png';
import image6 from '../assets/6.png';
import image7 from '../assets/7.png';
import image8 from '../assets/8.png';
import image9 from '../assets/9.png';
import image10 from '../assets/10.png';
import image11 from '../assets/11.png';
import image12 from '../assets/12.png';
import image13 from '../assets/13.png';
import image14 from '../assets/14.png';
import image15 from '../assets/15.png';
import image16 from '../assets/16.png';
import image17 from '../assets/17.png';
import image18 from '../assets/18.png';
import image19 from '../assets/19.png';
import image20 from '../assets/20.png';
import image21 from '../assets/21.png';
import image22 from '../assets/22.png';
import image23 from '../assets/23.png';
import image24 from '../assets/24.png';
import image25 from '../assets/25.png';
import image26 from '../assets/26.png';
import image27 from '../assets/27.png';
import image28 from '../assets/28.png';
import image29 from '../assets/29.png';
import image30 from '../assets/30.png';
import image31 from '../assets/31.png';
import image32 from '../assets/32.png';
import image33 from '../assets/33.png';
import image34 from '../assets/34.png';
import image35 from '../assets/35.png';
import image36 from '../assets/36.png';
import image37 from '../assets/37.png';
import image38 from '../assets/38.png';
import image39 from '../assets/39.png';
import image40 from '../assets/40.png';
import image41 from '../assets/41.png';
import image42 from '../assets/42.png';
import image43 from '../assets/43.png';
import image44 from '../assets/44.png';
import image45 from '../assets/45.png';
import image46 from '../assets/46.png';
import image47 from '../assets/47.png';
import image48 from '../assets/48.png';
import image49 from '../assets/49.png';
import image50 from '../assets/50.png';
import image51 from '../assets/51.png';
import image52 from '../assets/52.png';
import image53 from '../assets/53.png';
import image54 from '../assets/54.png';
import image55 from '../assets/55.png';
import image56 from '../assets/56.png';
import image57 from '../assets/57.png';
import image58 from '../assets/58.png';
import image59 from '../assets/59.png';
import image60 from '../assets/60.png';
import image61 from '../assets/61.png';
import image62 from '../assets/62.png';
import image63 from '../assets/63.png';


const data = [
  { id: 'collection1', imgSrc: image1, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/64' },
  { id: 'collection1', imgSrc: image2, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/61' },
  { id: 'collection1', imgSrc: image3, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/49' },
  { id: 'collection1', imgSrc: image4, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/2' },
  { id: 'collection1', imgSrc: image5, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/63' },
  { id: 'collection1', imgSrc: image6, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/51' },
  { id: 'collection1', imgSrc: image7, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/29' },
  { id: 'collection1', imgSrc: image8, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/57' },
  { id: 'collection1', imgSrc: image9, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/9' },
  { id: 'collection1', imgSrc: image10, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/28' },
  { id: 'collection1', imgSrc: image11, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/19' },
  { id: 'collection1', imgSrc: image12, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/17' },
  { id: 'collection1', imgSrc: image13, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/59' },
  { id: 'collection2', imgSrc: image14, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/58' },
  { id: 'collection2', imgSrc: image15, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/52' },
  { id: 'collection2', imgSrc: image16, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/40' },
  { id: 'collection2', imgSrc: image17, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/32' },
  { id: 'collection2', imgSrc: image18, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/26' },
  { id: 'collection2', imgSrc: image19, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/10' },
  { id: 'collection2', imgSrc: image20, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/25' },
  { id: 'collection2', imgSrc: image21, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/24' },
  { id: 'collection2', imgSrc: image22, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/62' },
  { id: 'collection2', imgSrc: image23, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/42' },
  { id: 'collection2', imgSrc: image24, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/27' },
  { id: 'collection2', imgSrc: image25, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/23' },
  { id: 'collection2', imgSrc: image26, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/7' },
  { id: 'collection3', imgSrc: image27, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/46' },
  { id: 'collection3', imgSrc: image28, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/43' },
  { id: 'collection3', imgSrc: image29, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/54' },
  { id: 'collection3', imgSrc: image30, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/15' },
  { id: 'collection3', imgSrc: image31, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/14' },
  { id: 'collection3', imgSrc: image32, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/13' },
  { id: 'collection3', imgSrc: image33, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/60' },
  { id: 'collection3', imgSrc: image34, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/50' },
  { id: 'collection3', imgSrc: image35, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/48' },
  { id: 'collection3', imgSrc: image36, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/44' },
  { id: 'collection3', imgSrc: image37, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/37' },
  { id: 'collection3', imgSrc: image38, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/18' },
  { id: 'collection3', imgSrc: image39, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/65' },
  { id: 'collection4', imgSrc: image40, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/12' },
  { id: 'collection4', imgSrc: image41, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/6' },
  { id: 'collection4', imgSrc: image42, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/41' },
  { id: 'collection4', imgSrc: image43, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/20' },
  { id: 'collection4', imgSrc: image44, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/8' },
  { id: 'collection4', imgSrc: image45, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/47' },
  { id: 'collection4', imgSrc: image46, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/4' },
  { id: 'collection4', imgSrc: image47, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/38' },
  { id: 'collection4', imgSrc: image48, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/21' },
  { id: 'collection4', imgSrc: image49, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/55' },
  { id: 'collection4', imgSrc: image50, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/3' },
  { id: 'collection4', imgSrc: image51, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/22' },
  { id: 'collection5', imgSrc: image52, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/16' },
  { id: 'collection5', imgSrc: image53, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/53' },
  { id: 'collection5', imgSrc: image54, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/35' },
  { id: 'collection5', imgSrc: image55, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/45' },
  { id: 'collection5', imgSrc: image56, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/31' },
  { id: 'collection5', imgSrc: image57, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/39' },
  { id: 'collection5', imgSrc: image58, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/56' },
  { id: 'collection5', imgSrc: image59, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/33' },
  { id: 'collection5', imgSrc: image60, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/11' },
  { id: 'collection5', imgSrc: image61, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/1' },
  { id: 'collection5', imgSrc: image62, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/34' },
  { id: 'collection5', imgSrc: image63, link: 'https://opensea.io/assets/ethereum/0xcd8873bc26da6bf794e758f04e73193307514112/36' }
];

const TabContent = ({ imgSrc, link }) => (
  <div className="blurb">
    <a href={link} target="_blank" rel="noopener noreferrer">
      <img src={imgSrc} alt="Description Image" />
    </a>
  </div>
);

const Tabs = () => {
  const [activeTab, setActiveTab] = useState('collection1');

  useEffect(() => {
    const savedTab = localStorage.getItem('activeTab');
    if (savedTab) {
      setActiveTab(savedTab);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('activeTab', activeTab);
  }, [activeTab]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const tabs = ['collection1', 'collection2', 'collection3', 'collection4', 'collection5'];

  const getFilteredData = (tab) => {
    return data.filter(item => item.id === tab);
  };

  return (
    <div id='tabs'>
      <div className="tab">
        {tabs.map(tab => (
          <button
            key={tab}
            className={`tablinks ${activeTab === tab ? 'active' : ''}`}
            onClick={() => handleTabClick(tab)}
          >
            {tab.replace('_', ' ')}
          </button>
        ))}
      </div>

      <div className="blurb-container">
        {getFilteredData(activeTab).map(item => (
          <TabContent
            key={item.imgSrc}
            imgSrc={item.imgSrc}
            link={item.link}
          />
        ))}
      </div>
    </div>
  );
};

export default Tabs;



/*
import React, { useState } from 'react';

import image1 from '../assets/korean_friendship_abstractionism.jpg';
import image2 from '../assets/korean_friendship_artists_sketch.jpg';
import image3 from '../assets/korean_friendship_blossom_season.jpg';
import image4 from '../assets/korean_friendship_blue.jpg';
import image5 from '../assets/korean_friendship_blue_blooming.jpg';
import image6 from '../assets/korean_friendship_brick.jpg';
import image7 from '../assets/korean_friendship_byzantine.jpg';
import image8 from '../assets/korean_friendship_christmas.jpg';
import image9 from '../assets/korean_friendship_christmas.jpg'; // Example image for Tab5
import image10 from '../assets/korean_friendship_abstractionism.jpg'; // Example image for Tab5

const data = [
  {
    id: 'Tab1',
    imgSrc: image1,
    link: 'https://example.com/link1'
  },
  {
    id: 'Tab1',
    imgSrc: image5,
    link: 'https://example.com/link5'
  },
  {
    id: 'Tab2',
    imgSrc: image2,
    link: 'https://example.com/link2'
  },
  {
    id: 'Tab2',
    imgSrc: image6,
    link: 'https://example.com/link6'
  },
  {
    id: 'Tab3',
    imgSrc: image3,
    link: 'https://example.com/link3'
  },
  {
    id: 'Tab3',
    imgSrc: image7,
    link: 'https://example.com/link7'
  },
  {
    id: 'Tab4',
    imgSrc: image4,
    link: 'https://example.com/link4'
  },
  {
    id: 'Tab4',
    imgSrc: image8,
    link: 'https://example.com/link8'
  },
  {
    id: 'Tab5',
    imgSrc: image9,
    link: 'https://example.com/link9'
  },
  {
    id: 'Tab5',
    imgSrc: image10,
    link: 'https://example.com/link10'
  }
];

const TabContent = ({ imgSrc, link }) => (
  <div className="blurb">
    <a href={link} target="_blank" rel="noopener noreferrer">
      <img src={imgSrc} alt="Description Image" />
    </a>
  </div>
);

const Tabs = () => {
  const [activeTab, setActiveTab] = useState('Tab1'); // Set a default active tab

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const tabs = ['Tab1', 'Tab2', 'Tab3', 'Tab4', 'Tab5']; // Define your tabs here

  const getFilteredData = (tab) => {
    return data.filter(item => item.id === tab);
  };

  const getCount = (tab) => {
    return data.filter(item => item.id === tab).length;
  };

  return (
    <div id='tabs'>
      <div className="tab">
        {tabs.map(tab => (
          <button
            key={tab}
            className={`tablinks ${activeTab === tab ? 'active' : ''}`}
            onClick={() => handleTabClick(tab)}
          >
            {`${tab.replace('_', ' ')} (${getCount(tab)})`}
          </button>
        ))}
      </div>

      <div className="blurb-container">
        {getFilteredData(activeTab).map(item => (
          <TabContent
            key={item.imgSrc}
            imgSrc={item.imgSrc}
            link={item.link}
          />
        ))}
      </div>
    </div>
  );
};

export default Tabs;
*/



/*
import React, { useState } from 'react';

import image1 from '../assets/korean_friendship_abstractionism.jpg';
import image2 from '../assets/korean_friendship_artists_sketch.jpg';
import image3 from '../assets/korean_friendship_blossom_season.jpg';
import image4 from '../assets/korean_friendship_blue.jpg';
import image5 from '../assets/korean_friendship_blue_blooming.jpg';
import image6 from '../assets/korean_friendship_brick.jpg';
import image7 from '../assets/korean_friendship_byzantine.jpg';
import image8 from '../assets/korean_friendship_christmas.jpg';

const data = [
  {
    id: 'Tab1',
    imgSrc: image1
  },
  {
    id: 'Tab1',
    imgSrc: image5
  },
  {
    id: 'Tab2',
    imgSrc: image2
  },
  {
    id: 'Tab2',
    imgSrc: image6
  },
  {
    id: 'Tab3',
    imgSrc: image3
  },
  {
    id: 'Tab3',
    imgSrc: image7
  },
  {
    id: 'Tab4',
    imgSrc: image4
  },
  {
    id: 'Tab4',
    imgSrc: image8
  }
];

const TabContent = ({ imgSrc }) => (
  <div className="blurb">
    <img src={imgSrc} alt="Description Image" />
  </div>
);

const Tabs = () => {
  const [activeTab, setActiveTab] = useState('All');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const getFilteredData = (tab) => {
    return tab === 'All' ? data : data.filter(item => item.id === tab);
  };

  const getCount = (tab) => {
    return tab === 'All' ? data.length : data.filter(item => item.id === tab).length;
  };

  return (
    <div id='tabs'>
      <div className="tab">
        {['All', 'Tab1', 'Tab2', 'Tab3', 'Tab4'].map(tab => (
          <button
            key={tab}
            className={`tablinks ${activeTab === tab ? 'active' : ''}`}
            onClick={() => handleTabClick(tab)}
          >
            {tab === 'All' ? `All (${getCount(tab)})` : `${tab.replace('_', ' ')} (${getCount(tab)})`}
          </button>
        ))}
      </div>

      <div className="blurb-container">
        {getFilteredData(activeTab).map(item => (
          <TabContent
            key={item.imgSrc}
            imgSrc={item.imgSrc}
          />
        ))}
      </div>
    </div>
  );
};

export default Tabs;
*/


/*
import React, { useState } from 'react';

import image1 from '../assets/korean_friendship_abstractionism.jpg';
import image2 from '../assets/korean_friendship_artists_sketch.jpg';
import image3 from '../assets/korean_friendship_blossom_season.jpg';
import image4 from '../assets/korean_friendship_blue.jpg';
import image5 from '../assets/korean_friendship_blue_blooming.jpg';
import image6 from '../assets/korean_friendship_brick.jpg';
import image7 from '../assets/korean_friendship_byzantine.jpg';
import image8 from '../assets/korean_friendship_christmas.jpg';

const data = [
  {
    id: 'Tab1',
    imgSrc: image1
  },
  {
    id: 'Tab1',
    imgSrc: image5
  },
  {
    id: 'Tab2',
    imgSrc: image2
  },
  {
    id: 'Tab2',
    imgSrc: image6
  },
  {
    id: 'Tab3',
    imgSrc: image3
  },
  {
    id: 'Tab3',
    imgSrc: image7
  },
  {
    id: 'Tab4',
    imgSrc: image4
  },
  {
    id: 'Tab4',
    imgSrc: image8
  }
];

const TabContent = ({ imgSrc }) => (
  <div className="blurb">
    <img src={imgSrc} alt="Description Image" />
  </div>
);

const Tabs = () => {
  const [activeTab, setActiveTab] = useState('All');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const getFilteredData = (tab) => {
    return tab === 'All' ? data : data.filter(item => item.id === tab);
  };

  const getCount = (tab) => {
    return tab === 'All' ? data.length : data.filter(item => item.id === tab).length;
  };

  return (
    <div id='tabs'>
      <div className="tab">
        {['All', 'Tab1', 'Tab2', 'Tab3', 'Tab4'].map(tab => (
          <button
            key={tab}
            className={`tablinks ${activeTab === tab ? 'active' : ''}`}
            onClick={() => handleTabClick(tab)}
            disabled={tab !== 'All'}
          >
            {tab === 'All' ? `All (${getCount(tab)})` : `${tab.replace('_', ' ')} (${getCount(tab)})`}
          </button>
        ))}
      </div>

      <div className="blurb-container">
        {getFilteredData(activeTab).map(item => (
          <TabContent
            key={item.imgSrc}
            imgSrc={item.imgSrc}
          />
        ))}
      </div>
    </div>
  );
};

export default Tabs;
*/