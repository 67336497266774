import Navigation from "./Navigation";


const Header = () => {
    return (
        <section id="header">
            <Navigation />

            <div className="container">
                <div className="main-container">
                    <div className="title">
                        <h2>
                            Welcome to<br />
                            BE Creations!
                        </h2>
                    </div>

                    <div className="headercontent">
                        <p>
                            At BE Creations, we delve into the realms of imagination and artistry, 
                            creating unique and captivating NFTs that transport you to alternate realities. 
                            Our latest collection, the Korean Friendship Bell Collection, reimagines the 
                            iconic Korean Friendship Bell across diverse and fantastical universes. 
                            Each piece in our collection is a journey into a different world, blending 
                            cultural heritage with visionary art.
                        </p>
                    </div>

                    <div className="btn-container">
                        <div className="btn">
                            <a href="https://opensea.io/collection/korean-friendship-bell" target="_blank" rel="noreferrer"><p>Buy on Opensea</p></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Header;