import './App.css';
import './Responsive.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header';
import Nfts from './components/Nfts';
import Roadmap from './components/Roadmap';
import Tabs from './components/Tabs';
import Reviews from './components/Reviews';
import Banner from './components/Banner';
import Faqs from './components/Faqs';
import Vision from './components/Vision';

function App() {
  return (
    <div className="body">
      <Header />
      <Vision />
      <Nfts />
      <Tabs />
      <Roadmap />
      <Reviews />
      <Banner />
      <Faqs />
    </div>
  );
}

export default App;
