import React, { useEffect, useState } from "react";
import Faq from "react-faq-component";
import footerlogo from '../assets/logo.png';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTwitter,
    faDiscord,
    faInstagram
  } from "@fortawesome/free-brands-svg-icons";


const data = {
    rows: [
        {
            title: "What is an NFT?",
            content: `An NFT, or Non-Fungible Token, is a unique digital asset that represents ownership of a specific item or piece of content, such as artwork, music, or virtual real estate, on the blockchain.`,
        },
        {
            title: "How do NFTs work?",
            content:
                "NFTs use blockchain technology to certify the uniqueness and ownership of a digital asset. Each NFT is stored on a blockchain ledger, making it immutable and verifiable.",
        },
        {
            title: "What can NFTs be used for?",
            content: `NFTs can represent ownership of various digital assets, including art, music, videos, in-game items, virtual real estate, and even tweets. `,
        },
        {
            title: "What blockchain does your platform use?",
            content: "Our platform primarily uses Ethereum. Each NFT on our platform is recorded on this blockchain.",
        },
                {
            title: "What is the difference between NFTs and cryptocurrencies?",
            content: "While both are digital assets on a blockchain, NFTs are unique and indivisible, whereas cryptocurrencies like Bitcoin or Ethereum are fungible and can be divided.",
        },
    ],
};

const styles = {
    bgColor: 'transparent',
    titleTextColor: "#7CDA24",
    rowTitleColor: "#7CDA24",
    rowContentColor: 'white',
    arrowColor: "#7CDA24",
};

const config = {
    animate: true,
    arrowIcon: "V",
    openOnload: 0,
    expandIcon: "+",
    collapseIcon: "-",
};

const Faqs = () => {

    const currentYear = new Date().getFullYear();

    return (
        <section id="faq">
            <div className="container">
                <div className="faqinner_container">
                    <div className="faq_heading">
                        <h2>Frequently Asked Questions</h2>
                    </div>

                    <div className="faq_container">
                        <Faq
                            data={data}
                            styles={styles}
                            config={config}
                        />
                    </div>

                    <div className="footerlogo">
                        <img src={footerlogo} alt="footer logo" />
                    </div>

                    <div className="footer-text">
                        <h2>Join Our Discord Today</h2>
                    </div>

                    <div className="footer-btn">
                        <div className="footer-btn-container">
                            <a href="https://discord.gg/HNydHcyc" target="_blank" rel="noreferrer"><p>Join Discord</p></a>
                        </div>
                    </div>

                    <div className="social-icons">
                        <a href="https://x.com/BeCreationsNFT" target="_blank"><FontAwesomeIcon icon={faTwitter} size="1x" /></a> &nbsp; &nbsp;
                        <a href="https://discord.gg/HNydHcyc" target="_blank"><FontAwesomeIcon icon={faDiscord} size="1x" /></a> &nbsp; &nbsp;
                        <a href="https://www.instagram.com/becreationsnft/" target="_blank"><FontAwesomeIcon icon={faInstagram} size="1x" /></a>
                    </div>

                    <div className="footerCredits">
                        <p>Copyright ©{currentYear} All rights reserved BE Creations</p>
                    </div>

                </div>
            </div>
        </section>
    );
}

export default Faqs;