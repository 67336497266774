import React, { useState, useRef } from 'react';
import video from '../video/video.mp4';
import playIcon from '../assets/play.png'; // Add a play icon image in your assets folder

const Reviews = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <section id="Reviews">
      <div className="container">
        <div className="inner_container">
          <div className="Reviews_heading">
            {/*<h2>What Customers Says</h2>*/}
          </div>

          <div className="video_container" onClick={handlePlayPause}>
            <video ref={videoRef} src={video} />
            {!isPlaying && (
              <div className="play-button">
                <img src={playIcon} alt="Play" />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Reviews;
