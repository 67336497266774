import image1 from '../assets/8.png';

const Vision = () => {
    return (
        <section id="about">
            <div className="container">
                <div className="main-aboutcontainer">
                    <div className="aboutus">

                        <div className="leftabout-container">
                            <div className="about-text">
                                <h2>Our Vision</h2>
                                <p>
                                    Our vision is to bridge the gap between traditional art forms and modern digital artistry. 
                                    We aim to create a community where art enthusiasts and collectors can explore, appreciate, 
                                    and own a piece of these alternate realities. Through our NFTs, we seek to inspire, educate, 
                                    and foster a deeper connection with the digital art world."
                                </p>
                            </div>
                        </div>
                        <div className="rightabout-container">
                            <div className="aboutimg">
                                <img src={image1} alt="Your Alt Text" />
                            </div>
                        </div>
                        <div style={{ clear: 'both' }}></div>

                    </div>
                </div>
            </div>
        </section>
    );
}

export default Vision;