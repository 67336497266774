/*
const roadmapData = [
    { 
        phase: "Phase 1", 
        title1: "#1.3: Community Building",
        description1: "Establish social media accounts (Twitter,Instagram, Discord etc) to engage with NFT enthusiasts and build a community.",

            title2: "#1.2: OpenSea Listing",
            description2:"Launch the BC Creations NFT collection on OpenSea.",
     
            title3: "#1.1: Ideation and Creation",
            description3: "Develop a series of unique digital artworks, including the Korean Friendship Bell designs and other thematic universes.",
         },
         { 
             phase: "Phase 2", 
             title1: "#2.4: New NFT Drops",
             description1: "Regularly release new NFTs in themed series to keep the collection fresh and exciting.",

            title2: "#2.3: Giveaways and Contests",
            description2:"Host giveaways and contests to increase engagement and reward the community.",
     
            title3: "#2.2: Influencer Partnerships",
            description3:"Collaborate with NFT influe- ncers to promote the collection and reach a wider audience.",
     
            title4: "#2.1: Social Media Campaigns",
            description4:"Implement targeted marketing campaigns on social media platf- orms to raise awareness and attract collectors.",
         },
         { 
             phase: "Phase 3", 
             title1: "#3.2: Community Events",
             description1: "Host virtual events, AMAs, and live Q&A sessions to maintain engagement with the community",

     
             title2: "#3.1: Collaborations",
             description2: "Partner with other artists and NFT projects to create exclusive, limited-edition pieces.",
         },
         { 
             phase: "Phase 4", 
             title1: "#4.3: Charity Initiatives",
             description1: "Organize charity auctions and collaborations to give back to the community and support meaningful causes.",

            title2: "#4.2: Feedback Collection",
            description2:"Regularly gather feedback from the community to improve the project and address any concerns.",
     
            title3: "#4.1: Exclusive Benefits",
            description3: "Introduce exclusive benefits for holders, such as early access to releases, special content, & physical merchandise.",
         },
     ];
     
     const Roadmap = () => {
         return (
             <section id="roadmap">
                 <div className="container">
                     <div className="gradient-bg">
                         <div className="roadmap-container">
                             <div className="roadmap-title">
                                 <h2>Roadmap</h2>
                             </div>
     
                             <div className="roadmap-content">
                                 {roadmapData.map((milestone, index) => (
                                     <div className="content" key={index}>
                                         <h2>{milestone.phase}</h2>
                                         <h3>{milestone.title1}</h3>
                                         <p>{milestone.description1}</p>
     
                                         <h3>{milestone.title2}</h3>
                                         <p>{milestone.description2}</p>
     
                                         <h3>{milestone.title3}</h3>
                                         <p>{milestone.description3}</p>
     
                                         <h3>{milestone.title4}</h3>
                                         <p>{milestone.description4}</p>
                                     </div>
                                 ))}
                             </div>
                         </div>
                     </div>
                 </div>
             </section>
         );
     }
     
     export default Roadmap;
*/ 

const roadmapData = [
    { 
        phase: "Phase 1", 
        title1: "Community Building",
        description1: "Establish social media accounts (Twitter, Instagram, Discord, etc.) to engage with NFT enthusiasts and build a community.",
        title2: "OpenSea Listing",
        description2:"Launch the BE Creations NFT collection on OpenSea.",
        title3: "Ideation and Creation",
        description3: "Develop a series of unique digital artworks, including the Korean Friendship Bell designs and other thematic universes.",
    },
    { 
        phase: "Phase 2", 
        title1: "New NFT Drops",
        description1: "Regularly release new NFTs in themed series to keep the collection fresh and exciting.",
        title2: "Giveaways and Contests",
        description2:"Host giveaways and contests to increase engagement and reward the community.",
        title3: "Influencer Partnerships",
        description3:"Collaborate with NFT influencers to promote the collection and reach a wider audience.",
        title4: "Social Media Campaigns",
        description4:"Implement targeted marketing campaigns on social media platforms to raise awareness and attract collectors.",
    },
    { 
        phase: "Phase 3", 
        title1: "Community Events",
        description1: "Host virtual events, AMAs, and live Q&A sessions to maintain engagement with the community",
        title2: "Collaborations",
        description2: "Partner with other artists and NFT projects to create exclusive, limited-edition pieces.",
    },
    { 
        phase: "Phase 4", 
        title1: "Charity Initiatives",
        description1: "Organize charity auctions and collaborations to give back to the community and support meaningful causes.",
        title2: "Feedback Collection",
        description2:"Regularly gather feedback from the community to improve the project and address any concerns.",
        title3: "Exclusive Benefits",
        description3: "Introduce exclusive benefits for holders, such as early access to releases, special content, and physical merchandise.",
    },
];


const Roadmap = () => {
    return (
        <section id="roadmap">
            <div className="container">
                <div className="gradient-bg">
                    <div className="roadmap-container">
                        <div className="roadmap-title">
                            <h2>Roadmap</h2>
                        </div>

                        <div className="roadmap-content">
                            {roadmapData.map((milestone, index) => (
                                <div className="content" key={index}>
                                    <h2>{milestone.phase}</h2>
                                    <ul>
                                        <li>
                                            <h3>{milestone.title1}</h3>
                                            <p>{milestone.description1}</p>
                                        </li>
                                        <li>
                                            <h3>{milestone.title2}</h3>
                                            <p>{milestone.description2}</p>
                                        </li>
                                        {milestone.title3 && (
                                            <li>
                                                <h3>{milestone.title3}</h3>
                                                <p>{milestone.description3}</p>
                                            </li>
                                        )}
                                        {milestone.title4 && (
                                            <li>
                                                <h3>{milestone.title4}</h3>
                                                <p>{milestone.description4}</p>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Roadmap;