import image1 from '../assets/korean_friendship_abstractionism.jpg';
import image2 from '../assets/korean_friendship_artists_sketch.jpg';
import image3 from '../assets/korean_friendship_blossom_season.jpg';
import image4 from '../assets/korean_friendship_blue.jpg';
import grad from '../assets/grad-1.png';

const Data = [
    {
        id: 1,
        photo: image1
    },
    {
        id: 2,
        photo: image2
    },
    {
        id: 3,
        photo: image3
    },
    {
        id: 4,
        photo: image4
    },
]


const Nfts = () => {
    return (
        <section id="nfts">
            
            <div className="container">
                <div className='nft-container'>
                    {Data.map((item, index) => (
                        <div key={index} className="inline-div">
                            <img src={item.photo} alt="NFT" />
                        </div>
                    ))}
                </div>
            </div>

            <div className='gradient'>
                <img src={grad} alt="gradient" />
            </div>
        </section>
    );
}

export default Nfts;