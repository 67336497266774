import logo from '../assets/logo.png';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTwitter,
    faDiscord,
    faInstagram
  } from "@fortawesome/free-brands-svg-icons";


const Navigation = () => {

  return (
    <>
      {['xl'].map((expand) => (
        <Navbar key={expand} expand={expand} className="mb-3 navbarBg" style={{ background: 'transparent' }}>
          <Container fluid>
            <Navbar.Brand href="#" id="logo-heading"><img className='inline' src={logo} alt='' /></Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header id='close' closeButton>
                <Offcanvas.Title className='mbl-title' id={`offcanvasNavbarLabel-expand-${expand}`}>
                BE Creations
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="me-auto links">
                  <Nav.Link className="nav-items text-nowrap" href="https://x.com/BeCreationsNFT" target="_blank"><FontAwesomeIcon icon={faTwitter} size="1x" /></Nav.Link>
                  <Nav.Link className="nav-items text-nowrap" href="https://discord.gg/HNydHcyc" target="_blank"><FontAwesomeIcon icon={faDiscord} size="1x" /></Nav.Link>
                  <Nav.Link className="nav-items text-nowrap" href="https://www.instagram.com/becreationsnft/" target="_blank"><FontAwesomeIcon icon={faInstagram} size="1x" /></Nav.Link>
                  <div className="nav-items text-nowrap play-btn">
                    <a href='#' target="_blank" rel="noopener noreferrer"><button>Whitepaper</button></a>
                  </div>
                </Nav>

              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default Navigation;